import axios from "axios"

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"

import { BASE_ENDPOINT } from "../../constants"
import {
  Alert,
  ApiError,
  ApiPaginatedResponse,
  ApiStatus,
  Notification,
} from "../../types"

export interface NotificationsState {
  alerts: Alert[]
  notifications: ApiPaginatedResponse<Notification>
  unreadCount: number
  apiStatus: { [action: string]: ApiStatus }
  apiError: { [action: string]: ApiError }
}

const initialState: NotificationsState = {
  alerts: [],
  notifications: {} as ApiPaginatedResponse<Notification>,
  unreadCount: 0,
  apiStatus: {},
  apiError: {},
}

type ListNotificationsArgs = {
  recipients__id__in: string
  unread?: string
  page?: string
  page_size?: string
}

export const listNotifications = createAsyncThunk(
  "notifications/listNotifications",
  async (args: ListNotificationsArgs, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/comms/notifications/`
    try {
      const response = await axios.get<ApiPaginatedResponse<Notification>>(
        url,
        {
          params: args,
        }
      )
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

type ReadNotificationArgs = {
  id: string
  user_id: string
}

export const readNotification = createAsyncThunk(
  "notifications/readNotification",
  async (args: ReadNotificationArgs, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/comms/notifications/${args.id}/has_read/`
    try {
      const response = await axios.patch<
        ApiPaginatedResponse<{ read: boolean }>
      >(url, {
        user_id: args.user_id,
      })
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setAlert: (state, action: PayloadAction<Alert>) => {
      const alert: Alert = {
        ...action.payload,
        timestamp: new Date().getTime(),
      }
      state.alerts = [...state.alerts, alert]
    },
    dismissAlert: (state, action: PayloadAction<number>) => {
      const index = action.payload
      state.alerts.splice(index, 1)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listNotifications.pending, (state, action) => {
        state.apiStatus["listNotifications"] = ApiStatus.PENDING
      })
      .addCase(listNotifications.fulfilled, (state, action) => {
        if (action.meta.arg.unread) {
          state.unreadCount = action.payload.results.length
        } else {
          state.notifications =
            action.payload as ApiPaginatedResponse<Notification>
        }
        state.apiStatus["listNotifications"] = ApiStatus.FULFILLED
      })
      .addCase(listNotifications.rejected, (state, action) => {
        state.apiError["listNotifications"] = action.payload as ApiError
        state.apiStatus["listNotifications"] = ApiStatus.REJECTED
      })
  },
})

export const { setAlert, dismissAlert } = notificationsSlice.actions
export default notificationsSlice.reducer
