import axios from "axios"

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { BASE_ENDPOINT } from "../../constants"
import {
  ApiStatus,
  ApiError,
  ApiAction,
  BillingAddress,
  Tax,
  ApiPaginatedResponse,
  PaymentConfig,
  Invoice,
  InvoiceStatus,
  Transaction,
  TransactionStatus,
} from "../../types"

export interface PaymentsState {
  taxes: ApiPaginatedResponse<Tax>
  paymentConfigs: { [key: string]: ApiPaginatedResponse<PaymentConfig> }
  invoices: ApiPaginatedResponse<Invoice>
  transactions: ApiPaginatedResponse<Transaction>
  apiStatus: { [action: string]: ApiStatus }
  apiError: { [action: string]: ApiError }
}

const initialState: PaymentsState = {
  taxes: {} as ApiPaginatedResponse<Tax>,
  paymentConfigs: {},
  invoices: {} as ApiPaginatedResponse<Invoice>,
  transactions: {} as ApiPaginatedResponse<Transaction>,
  apiStatus: {},
  apiError: {},
}

// Billing addresses

export const createBillingAddress = createAsyncThunk(
  "payments/createBillingAddress",
  async (args: BillingAddress & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/payments/billing-addresses/`
    try {
      const response = await axios.post<BillingAddress>(url, args)
      args.onFulfill({ id: response.data.id! })
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const updateBillingAddress = createAsyncThunk(
  "payments/updateBillingAddress",
  async (args: BillingAddress & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/payments/billing-addresses/${args.id}/`
    try {
      const response = await axios.patch<BillingAddress>(url, args)
      args.onFulfill({ id: response.data.id! })
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

// Taxes

type GetTaxesArgs = {
  country: string
}

export const getTaxes = createAsyncThunk(
  "payments/getTaxes",
  async (args: GetTaxesArgs, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/payments/taxes/`
    try {
      const response = await axios.get<ApiPaginatedResponse<Tax>>(url, {
        params: args,
      })
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

// Config

type ListPaymentConfig = {
  levels__id__in?: string
  courses__id__in?: string
  school?: string
  page?: number
  page_size?: number
}

export const listPaymentConfig = createAsyncThunk(
  "payments/listPaymentConfig",
  async (args: ListPaymentConfig, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/payments/config/`
    try {
      const response = await axios.get<ApiPaginatedResponse<PaymentConfig>>(
        url,
        {
          params: args,
        }
      )
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

// Invoices

type ListInvoices = {
  billing_address?: string
  payment_config?: string
  status?: InvoiceStatus
  status__in?: string
  page?: number
  page_size?: number
}

export const listInvoices = createAsyncThunk(
  "payments/listInvoices",
  async (args: ListInvoices, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/payments/invoices/`
    try {
      const response = await axios.get<ApiPaginatedResponse<Invoice>>(url, {
        params: args,
      })
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

// Transactions

type ListTransactions = {
  invoice?: string
  invoice__billing_address?: string
  status?: TransactionStatus
  page?: number
  page_size?: number
}

export const listTransactions = createAsyncThunk(
  "payments/listTransactions",
  async (args: ListTransactions, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/payments/transactions/`
    try {
      const response = await axios.get<ApiPaginatedResponse<Transaction>>(url, {
        params: args,
      })
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const paymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Taxes

      .addCase(getTaxes.pending, (state, action) => {
        state.apiStatus["getTaxes"] = ApiStatus.PENDING
      })
      .addCase(getTaxes.fulfilled, (state, action) => {
        state.taxes = action.payload as ApiPaginatedResponse<Tax>
        state.apiStatus["getTaxes"] = ApiStatus.FULFILLED
      })
      .addCase(getTaxes.rejected, (state, action) => {
        state.apiError["getTaxes"] = action.payload as ApiError
        state.apiStatus["getTaxes"] = ApiStatus.REJECTED
      })

      // Billing Addresses

      .addCase(createBillingAddress.pending, (state, action) => {
        state.apiStatus["createBillingAddress"] = ApiStatus.PENDING
      })
      .addCase(createBillingAddress.fulfilled, (state, action) => {
        state.apiStatus["createBillingAddress"] = ApiStatus.FULFILLED
      })
      .addCase(createBillingAddress.rejected, (state, action) => {
        state.apiError["createBillingAddress"] = action.payload as ApiError
        state.apiStatus["createBillingAddress"] = ApiStatus.REJECTED
      })

      .addCase(updateBillingAddress.pending, (state, action) => {
        state.apiStatus["updateBillingAddress"] = ApiStatus.PENDING
      })
      .addCase(updateBillingAddress.fulfilled, (state, action) => {
        state.apiStatus["updateBillingAddress"] = ApiStatus.FULFILLED
      })
      .addCase(updateBillingAddress.rejected, (state, action) => {
        state.apiError["updateBillingAddress"] = action.payload as ApiError
        state.apiStatus["updateBillingAddress"] = ApiStatus.REJECTED
      })

      // Payment configs

      .addCase(listPaymentConfig.pending, (state, action) => {
        let key = ""
        if (action.meta.arg.courses__id__in) {
          key = action.meta.arg.courses__id__in
        } else if (action.meta.arg.levels__id__in) {
          key = action.meta.arg.levels__id__in
        }
        state.apiStatus["listPaymentConfig" + key] = ApiStatus.PENDING
      })
      .addCase(listPaymentConfig.fulfilled, (state, action) => {
        let key = ""
        if (action.meta.arg.courses__id__in) {
          key = action.meta.arg.courses__id__in
        } else if (action.meta.arg.levels__id__in) {
          key = action.meta.arg.levels__id__in
        }
        state.paymentConfigs[key] =
          action.payload as ApiPaginatedResponse<PaymentConfig>
        state.apiStatus["listPaymentConfig" + key] = ApiStatus.FULFILLED
      })
      .addCase(listPaymentConfig.rejected, (state, action) => {
        let key = ""
        if (action.meta.arg.courses__id__in) {
          key = action.meta.arg.courses__id__in
        } else if (action.meta.arg.levels__id__in) {
          key = action.meta.arg.levels__id__in
        }
        state.apiError["listPaymentConfig" + key] = action.payload as ApiError
        state.apiStatus["listPaymentConfig" + key] = ApiStatus.REJECTED
      })

      // Invoices

      .addCase(listInvoices.pending, (state, action) => {
        state.apiStatus[`listInvoices${action.meta.arg.payment_config || ""}`] =
          ApiStatus.PENDING
      })
      .addCase(listInvoices.fulfilled, (state, action) => {
        state.invoices = action.payload as ApiPaginatedResponse<Invoice>
        state.apiStatus[`listInvoices${action.meta.arg.payment_config || ""}`] =
          ApiStatus.FULFILLED
      })
      .addCase(listInvoices.rejected, (state, action) => {
        state.apiError[`listInvoices${action.meta.arg.payment_config || ""}`] =
          action.payload as ApiError
        state.apiStatus[`listInvoices${action.meta.arg.payment_config || ""}`] =
          ApiStatus.REJECTED
      })

      // Transactions

      .addCase(listTransactions.pending, (state, action) => {
        state.apiStatus["listTransactions"] = ApiStatus.PENDING
      })
      .addCase(listTransactions.fulfilled, (state, action) => {
        state.transactions = action.payload as ApiPaginatedResponse<Transaction>
        state.apiStatus["listTransactions"] = ApiStatus.FULFILLED
      })
      .addCase(listTransactions.rejected, (state, action) => {
        state.apiError["listTransactions"] = action.payload as ApiError
        state.apiStatus["listTransactions"] = ApiStatus.REJECTED
      })
  },
})

export default paymentsSlice.reducer
