import React, { Suspense } from "react"
import { Routes, Route, Navigate, useLocation } from "react-router-dom"

import { isAuthenticated, logout } from "./features/accounts/accountsSlice"
import { useAppDispatch } from "./hooks"
import AppLoader from "./utils/AppLoader"

const DashboardLayout = React.lazy(() => import("./layouts/DashboardLayout"))
const SignInPage = React.lazy(() => import("./pages/Accounts/SignInPage"))
const SignUpPage = React.lazy(() => import("./pages/Accounts/SignUpPage"))
const ForgotPasswordPage = React.lazy(
  () => import("./pages/Accounts/ForgotPasswordPage")
)
const ResetPasswordPage = React.lazy(
  () => import("./pages/Accounts/ResetPasswordPage")
)
const VerifyAccountPage = React.lazy(
  () => import("./pages/Accounts/VerifyAccountPage")
)
const TermsAndPrivacyPage = React.lazy(
  () => import("./pages/Terms/TermsAndPrivacyPage")
)
const TutorSearchPage = React.lazy(
  () => import("./pages/Tutors/TutorSearchPage")
)

const App = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()

  const PrivateRoute = ({ children }: any) => {
    if (!isAuthenticated()) {
      dispatch(logout({ redirectUrl: location.pathname }))
      return <Navigate to="/sign-in" />
    } else {
      return children
    }
  }

  return (
    <Suspense fallback={<AppLoader />}>
      <Routes>
        <Route path="/sign-in" element={<SignInPage />} />
        <Route path="/sign-up" element={<SignUpPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
        <Route path="/verify-account" element={<VerifyAccountPage />} />
        <Route path="/terms-and-privacy" element={<TermsAndPrivacyPage />} />
        <Route path="/search" element={<TutorSearchPage />} />
        <Route
          path="*"
          element={
            <PrivateRoute>
              <DashboardLayout />
            </PrivateRoute>
          }
        />
      </Routes>
    </Suspense>
  )
}

export default App
