import axios from "axios"

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"

import { BASE_ENDPOINT } from "../../constants"
import {
  ApiAction,
  ApiError,
  ApiPaginatedResponse,
  ApiStatus,
  Chat,
  ChatStatus,
  Feedback,
  GroupChat,
} from "../../types"

export interface CommsState {
  directChatHeads: ApiPaginatedResponse<Chat>
  groupChatHeads: ApiPaginatedResponse<Chat>
  chats: { [action: string]: ApiPaginatedResponse<Chat> }
  chat: Chat
  groupChats: ApiPaginatedResponse<GroupChat>
  groupChat: GroupChat
  chatStatus: ChatStatus
  apiStatus: { [action: string]: ApiStatus }
  apiError: { [action: string]: ApiError }
}

const initialState: CommsState = {
  directChatHeads: {} as ApiPaginatedResponse<Chat>,
  groupChatHeads: {} as ApiPaginatedResponse<Chat>,
  chats: {},
  chat: {} as Chat,
  groupChats: {} as ApiPaginatedResponse<GroupChat>,
  groupChat: {} as GroupChat,
  chatStatus: {} as ChatStatus,
  apiStatus: {},
  apiError: {},
}

// Chats

export type ListChatsArgs = {
  sender?: string
  sender__in?: string
  recipient?: string
  recipient__in?: string
  sender_or_recipient?: string
  group?: string
  ungrouped?: boolean
  distinct?: string
  search?: string
  page?: number
  page_size?: number
  loadMore?: boolean
}

export const listChats = createAsyncThunk(
  "comms/listChats",
  async (args: ListChatsArgs, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/comms/chats/`
    try {
      const response = await axios.get<ApiPaginatedResponse<Chat>>(url, {
        params: args,
      })
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

// Group Chats

export const getGroupChat = createAsyncThunk(
  "comms/getGroupChat",
  async (groupId: string, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/comms/groups/${groupId}/`
    try {
      const response = await axios.get<GroupChat>(url)
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

export type ListGroupChatsArgs = {
  school?: string
  search?: string
  page?: number
  page_size?: number
}

export const listGroupChats = createAsyncThunk(
  "comms/listGroupChats",
  async (args: ListGroupChatsArgs, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/comms/groups/`
    try {
      const response = await axios.get<ApiPaginatedResponse<GroupChat>>(url, {
        params: args,
      })
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const createGroupChat = createAsyncThunk(
  "comms/createGroupChat",
  async (args: GroupChat & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/comms/groups/`
    const formData = new FormData()
    formData.append("name", args.name)
    formData.append("description", args.description)
    formData.append("school", args.school)
    if (args.icon) {
      formData.append("icon", args.icon)
    }
    if (args.members_ids?.length) {
      formData.append("members_ids", args.members_ids.join(","))
    }

    try {
      const response = await axios.post<GroupChat>(url, formData)
      args.onFulfill({ id: response.data.id! })
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const updateGroupChat = createAsyncThunk(
  "comms/updateGroupChat",
  async (args: GroupChat & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/comms/groups/`
    const formData = new FormData()
    formData.append("name", args.name)
    formData.append("description", args.description)
    formData.append("school", args.school)
    if (args.icon) {
      formData.append("icon", args.icon)
    }
    if (args.members_ids?.length) {
      formData.append("members_ids", args.members_ids.join(","))
    }

    try {
      const response = await axios.patch<GroupChat>(url)
      args.onFulfill({ id: response.data.id! })
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const createMultiMediaChat = createAsyncThunk(
  "comms/createMultiMediaChat",
  async (args: Chat & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/comms/chats/`
    const formData = new FormData()
    formData.append("message", args.message)
    formData.append("attachment", args.attachment!)
    formData.append("sender", args.sender)
    if (args.recipient) {
      formData.append("recipient", args.recipient!)
    } else if (args.group) {
      formData.append("group", args.group!)
    }
    formData.append("school", args.school!)

    try {
      const response = await axios.post<Chat>(url, formData)
      args.onFulfill({ item: response.data })
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

// Feedback

export type ListFeedbackArgs = {
  sender?: string
  recipient?: string
  category?: string
  course?: string
  school?: string
  page?: number
  page_size?: number
}

export const listFeedback = createAsyncThunk(
  "comms/listFeedback",
  async (args: ListFeedbackArgs & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/comms/feedback/`
    try {
      const response = await axios.get<ApiPaginatedResponse<Feedback>>(url, {
        params: args,
      })
      args.onFulfill({ item: response.data })
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const createFeedback = createAsyncThunk(
  "comms/createFeedback",
  async (args: Feedback & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/comms/feedback/`
    try {
      const response = await axios.post<Feedback>(url, args)
      args.onFulfill()
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const commsSlice = createSlice({
  name: "comms",
  initialState,
  reducers: {
    addChat: (state, action: PayloadAction<{ chatId: string; chat: Chat }>) => {
      const chatId = action.payload.chatId
      const chat = action.payload.chat
      if (state.chats[chatId]) {
        state.chats[chatId].results = [chat, ...state.chats[chatId].results]
      }
    },
    fetchChats: (
      state,
      action: PayloadAction<{ chatId: string; response: any }>
    ) => {
      const chatId = action.payload.chatId
      const response = action.payload.response
      if (state.chats[chatId]) {
        state.chats[chatId].count = response.count
        state.chats[chatId].start_index = response.start_index
        state.chats[chatId].end_index = response.end_index
        state.chats[chatId].results = response.results
      }
    },
    updateChatStatus: (state, action: PayloadAction<ChatStatus>) => {
      state.chatStatus = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listChats.pending, (state, action) => {
        const distinct = action.meta.arg.distinct
        state.apiStatus[`listChats${distinct ? distinct : ""}`] =
          ApiStatus.PENDING
      })
      .addCase(listChats.fulfilled, (state, action) => {
        const distinct = action.meta.arg?.distinct
        const loadMore = action.meta.arg?.loadMore
        const chatId =
          action.meta.arg?.group || action.meta.arg?.sender__in || ""

        if (distinct === "chat_head") {
          state.directChatHeads = action.payload as ApiPaginatedResponse<Chat>
        } else if (distinct === "group") {
          state.groupChatHeads = action.payload as ApiPaginatedResponse<Chat>
        } else {
          const response = action.payload as ApiPaginatedResponse<Chat>
          if (!state.chats[chatId] || !loadMore) {
            state.chats[chatId] = {} as ApiPaginatedResponse<Chat>
          }

          state.chats[chatId].results = [
            ...(state.chats[chatId]?.results || []),
            ...response.results,
          ]
          state.chats[chatId].count = response.count
          state.chats[chatId].start_index = response.start_index
          state.chats[chatId].end_index = response.end_index
          state.chats[chatId].next = response.next
          state.chats[chatId].previous = response.previous
        }

        state.apiStatus[`listChats${distinct ? distinct : ""}`] =
          ApiStatus.FULFILLED
      })
      .addCase(listChats.rejected, (state, action) => {
        const distinct = action.meta.arg.distinct
        state.apiError[`listChats${distinct ? distinct : ""}`] =
          action.payload as ApiError
        state.apiStatus[`listChats${distinct ? distinct : ""}`] =
          ApiStatus.REJECTED
      })

      .addCase(createMultiMediaChat.pending, (state, action) => {
        state.apiStatus["createMultiMediaChat"] = ApiStatus.PENDING
      })
      .addCase(createMultiMediaChat.fulfilled, (state, action) => {
        state.chat = action.payload as Chat
        state.apiStatus["createMultiMediaChat"] = ApiStatus.FULFILLED
      })
      .addCase(createMultiMediaChat.rejected, (state, action) => {
        state.apiError["createMultiMediaChat"] = action.payload as ApiError
        state.apiStatus["createMultiMediaChat"] = ApiStatus.REJECTED
      })

      // GroupChat

      .addCase(listGroupChats.pending, (state, action) => {
        state.apiStatus["listGroupChats"] = ApiStatus.PENDING
      })
      .addCase(listGroupChats.fulfilled, (state, action) => {
        state.groupChats = action.payload as ApiPaginatedResponse<GroupChat>
        state.apiStatus["listGroupChats"] = ApiStatus.FULFILLED
      })
      .addCase(listGroupChats.rejected, (state, action) => {
        state.apiError["listGroupChats"] = action.payload as ApiError
        state.apiStatus["listGroupChats"] = ApiStatus.REJECTED
      })

      .addCase(getGroupChat.pending, (state, action) => {
        state.apiStatus["getGroupChat"] = ApiStatus.PENDING
      })
      .addCase(getGroupChat.fulfilled, (state, action) => {
        state.groupChat = action.payload as GroupChat
        state.apiStatus["getGroupChat"] = ApiStatus.FULFILLED
      })
      .addCase(getGroupChat.rejected, (state, action) => {
        state.apiError["getGroupChat"] = action.payload as ApiError
        state.apiStatus["getGroupChat"] = ApiStatus.REJECTED
      })

      .addCase(createGroupChat.pending, (state, action) => {
        state.apiStatus["createGroupChat"] = ApiStatus.PENDING
      })
      .addCase(createGroupChat.fulfilled, (state, action) => {
        state.groupChat = action.payload as GroupChat
        state.apiStatus["createGroupChat"] = ApiStatus.FULFILLED
      })
      .addCase(createGroupChat.rejected, (state, action) => {
        state.apiError["createGroupChat"] = action.payload as ApiError
        state.apiStatus["createGroupChat"] = ApiStatus.REJECTED
      })

      .addCase(updateGroupChat.pending, (state, action) => {
        state.apiStatus["updateGroupChat"] = ApiStatus.PENDING
      })
      .addCase(updateGroupChat.fulfilled, (state, action) => {
        state.groupChat = action.payload as GroupChat
        state.apiStatus["updateGroupChat"] = ApiStatus.FULFILLED
      })
      .addCase(updateGroupChat.rejected, (state, action) => {
        state.apiError["updateGroupChat"] = action.payload as ApiError
        state.apiStatus["updateGroupChat"] = ApiStatus.REJECTED
      })

      // Feedback

      .addCase(listFeedback.pending, (state, action) => {
        state.apiStatus["listFeedback"] = ApiStatus.PENDING
      })
      .addCase(listFeedback.fulfilled, (state, action) => {
        state.apiStatus["listFeedback"] = ApiStatus.FULFILLED
      })
      .addCase(listFeedback.rejected, (state, action) => {
        state.apiError["listFeedback"] = action.payload as ApiError
        state.apiStatus["listFeedback"] = ApiStatus.REJECTED
      })

      .addCase(createFeedback.pending, (state, action) => {
        state.apiStatus["createFeedback"] = ApiStatus.PENDING
      })
      .addCase(createFeedback.fulfilled, (state, action) => {
        state.apiStatus["createFeedback"] = ApiStatus.FULFILLED
      })
      .addCase(createFeedback.rejected, (state, action) => {
        state.apiError["createFeedback"] = action.payload as ApiError
        state.apiStatus["createFeedback"] = ApiStatus.REJECTED
      })
  },
})

export const { addChat, fetchChats, updateChatStatus } = commsSlice.actions
export default commsSlice.reducer
