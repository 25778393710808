import axios from "axios"

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { BASE_ENDPOINT } from "../../constants"
import {
  ApiStatus,
  Level,
  ApiPaginatedResponse,
  School,
  ApiError,
  Teacher,
  Student,
  Lesson,
  ApiAction,
  Assignment,
  PhysicalAddress,
  StudentRequest,
  Attachment,
  User,
  StudentAttendance,
  StudentStats,
  Qualification,
  Subject,
} from "../../types"
import { removeEmpty } from "../../utils"

export interface SchoolsState {
  school: School
  schools: ApiPaginatedResponse<School>
  level: Level
  levels: ApiPaginatedResponse<Level>
  subjects: ApiPaginatedResponse<Subject>
  teacher: Teacher
  teachers: ApiPaginatedResponse<Teacher>
  student: Student
  students: ApiPaginatedResponse<Student>
  lessons: ApiPaginatedResponse<Lesson>
  lesson: Lesson
  joinClassroomLink: string
  assignments: ApiPaginatedResponse<Assignment>
  requests: ApiPaginatedResponse<StudentRequest>
  courseRequests: { [courseId: string]: ApiPaginatedResponse<StudentRequest> }
  request: StudentRequest
  attachments: Attachment[]
  attendances: ApiPaginatedResponse<StudentAttendance>
  studentStats: StudentStats
  users: ApiPaginatedResponse<User>
  apiStatus: { [action: string]: ApiStatus }
  apiError: { [action: string]: ApiError }
}

const initialState: SchoolsState = {
  school: {} as School,
  schools: {} as ApiPaginatedResponse<School>,
  level: {} as Level,
  levels: {} as ApiPaginatedResponse<Level>,
  subjects: {} as ApiPaginatedResponse<Subject>,
  teacher: {} as Teacher,
  teachers: {} as ApiPaginatedResponse<Teacher>,
  student: {} as Student,
  students: {} as ApiPaginatedResponse<Student>,
  lessons: {} as ApiPaginatedResponse<Lesson>,
  lesson: {} as Lesson,
  joinClassroomLink: "",
  assignments: {} as ApiPaginatedResponse<Assignment>,
  requests: {} as ApiPaginatedResponse<StudentRequest>,
  courseRequests: {},
  request: {} as StudentRequest,
  attachments: [],
  attendances: {} as ApiPaginatedResponse<StudentAttendance>,
  studentStats: {} as StudentStats,
  users: {} as ApiPaginatedResponse<User>,
  apiStatus: {},
  apiError: {},
}

// Schools

type ListSchools = {
  page?: number
  page_size?: number
}

export const listSchools = createAsyncThunk(
  "schools/listSchools",
  async (args: ListSchools, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/schools/`
    try {
      const response = await axios.get<ApiPaginatedResponse<School>>(url, {
        params: args,
      })
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const getSchool = createAsyncThunk(
  "schools/getSchool",
  async (schoolId: string, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/schools/${schoolId}/`
    try {
      const response = await axios.get<School>(url)
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const listLevels = createAsyncThunk(
  "schools/listLevels",
  async (schoolId: string, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/schools/${schoolId}/levels/`
    try {
      const response = await axios.get<ApiPaginatedResponse<Level>>(url)
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

// Subjects

type ListSubjects = {
  schoolId: string
  search?: string
  courses__teacher?: string
  courses__levels?: string
  courses__teacher__user__gender?: string
  courses__hourly_charge_rate__gte?: number
  courses__hourly_charge_rate__lte?: number
  age_range?: string
  availability?: object
  page?: number
  page_size?: number
}

export const listSubjects = createAsyncThunk(
  "schools/listSubjects",
  async (args: ListSubjects, { rejectWithValue }) => {
    const { schoolId, ...otherArgs } = args
    const params = removeEmpty(otherArgs)
    const url = `${BASE_ENDPOINT}/schools/${schoolId}/subjects/`
    try {
      const response = await axios.get<ApiPaginatedResponse<Subject>>(url, {
        params,
      })
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

// Students

export const listStudents = createAsyncThunk(
  "schools/listStudents",
  async (schoolId: string, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/schools/${schoolId}/students/`
    try {
      const response = await axios.get<ApiPaginatedResponse<Student>>(url)
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

type UpdateStudentArgs = {
  schoolId: string
  user: string
  level_id?: string
  address_id?: string
}

export const updateStudent = createAsyncThunk(
  "schools/updateStudent",
  async (args: UpdateStudentArgs & ApiAction, { rejectWithValue }) => {
    const { schoolId, user, level_id, address_id } = args
    const url = `${BASE_ENDPOINT}/schools/${schoolId}/students/${user}/`
    try {
      const response = await axios.patch<Student>(url, { level_id, address_id })
      args.onFulfill({ id: response.data.user })
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

type StudentArgs = {
  schoolId: string
  userId: string
  page?: number
  page_size?: number
}

export const listStudentAssignments = createAsyncThunk(
  "schools/listStudentAssignments",
  async (args: StudentArgs, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/schools/${args.schoolId}/students/${args.userId}/assignments/`
    try {
      const response = await axios.get<ApiPaginatedResponse<Assignment>>(url)
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const listStudentAttendances = createAsyncThunk(
  "schools/listStudentAttendances",
  async (args: StudentArgs, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/schools/${args.schoolId}/students/${args.userId}/attendances/`
    try {
      const response = await axios.get<ApiPaginatedResponse<StudentAttendance>>(
        url
      )
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const getStudentStats = createAsyncThunk(
  "schools/getStudentStats",
  async (args: StudentArgs, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/schools/${args.schoolId}/students/${args.userId}/stats/`
    try {
      const response = await axios.get<StudentStats>(url)
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

// Teachers

export const listTeachers = createAsyncThunk(
  "schools/listTeachers",
  async (schoolId: string, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/schools/${schoolId}/teachers/`
    try {
      const response = await axios.get<ApiPaginatedResponse<Teacher>>(url)
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

type GetTeacherArgs = {
  schoolId: string
  user: string
}

export const getTeacher = createAsyncThunk(
  "schools/getTeacher",
  async (args: GetTeacherArgs, { rejectWithValue }) => {
    const { schoolId, user } = args
    const url = `${BASE_ENDPOINT}/schools/${schoolId}/teachers/${user}/`
    try {
      const response = await axios.get<Teacher>(url)
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

type UpdateTeacherArgs = {
  schoolId: string
  user: string
  position?: string
  bio?: string
  hourly_charge_rate?: number
  address_id: string
}

export const updateTeacher = createAsyncThunk(
  "schools/updateTeacher",
  async (args: UpdateTeacherArgs & ApiAction, { rejectWithValue }) => {
    const { schoolId, user, position, bio, hourly_charge_rate, address_id } =
      args
    const url = `${BASE_ENDPOINT}/schools/${schoolId}/teachers/${user}/`
    try {
      const response = await axios.patch<Teacher>(url, {
        position,
        bio,
        hourly_charge_rate,
        address_id,
      })
      args.onFulfill({ id: response.data.user })
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

type TeacherArgs = {
  schoolId: string
  userId: string
  page?: number
  page_size?: number
}

export const listTeacherAssignments = createAsyncThunk(
  "schools/listTeacherAssignments",
  async (args: TeacherArgs, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/schools/${args.schoolId}/teachers/${args.userId}/assignments/`
    try {
      const response = await axios.get<ApiPaginatedResponse<Assignment>>(url)
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const createTeacherQualification = createAsyncThunk(
  "schools/createTeacherQualification",
  async (args: Qualification & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/schools/${args.school}/teachers/${args.teacher}/qualifications/`
    const formData = new FormData()
    for (const [key, value] of Object.entries(args)) {
      if (typeof value === "function") {
        continue
      }
      formData.append(key, value)
    }

    try {
      const response = await axios.post<Qualification>(url, formData)
      args.onFulfill()
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

// Lessons

type ListLessonsArgs = {
  schoolId: string
  course?: string
  user_id?: string
  start_date?: string
  end_date?: string
  page?: number
  page_size?: number
}

export const listLessons = createAsyncThunk(
  "schools/listLessons",
  async (args: ListLessonsArgs, { rejectWithValue }) => {
    const { schoolId, course, page, page_size } = args
    const url = `${BASE_ENDPOINT}/schools/${schoolId}/lessons/`
    try {
      const response = await axios.get<ApiPaginatedResponse<Lesson>>(url, {
        params: { course, page, page_size },
      })
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

type DetailLessonArgs = {
  schoolId: string
  lessonId: string
}

export const getLesson = createAsyncThunk(
  "schools/getLesson",
  async ({ schoolId, lessonId }: DetailLessonArgs, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/schools/${schoolId}/lessons/${lessonId}/`
    try {
      const response = await axios.get<Lesson>(url)
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const createLesson = createAsyncThunk(
  "schools/createLesson",
  async (args: Lesson & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/schools/${args.school_id}/lessons/`
    const formData = new FormData()
    formData.append("name", args.name)
    formData.append("description", args.description)
    if (args.presentation) {
      formData.append("presentation", args.presentation)
    }
    formData.append("start_date", args.start_date!)
    formData.append("duration", String(args.duration! || 0))
    formData.append("repeat_rule", args.repeat_rule!)
    formData.append("repeat_until", args.repeat_until!)
    formData.append("color", args.color!)
    formData.append("course_id", args.course_id!)
    formData.append("school_id", args.school_id!)
    try {
      const response = await axios.post<Lesson>(url, formData)
      args.onFulfill({ id: response.data.id! })
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const updateLesson = createAsyncThunk(
  "schools/updateLesson",
  async (args: Lesson & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/schools/${args.school_id}/lessons/${args.id}/`
    const formData = new FormData()
    formData.append("name", args.name)
    formData.append("description", args.description)
    if (args.presentation) {
      formData.append("presentation", args.presentation)
    }
    formData.append("start_date", args.start_date!)
    formData.append("duration", String(args.duration! || 0))
    formData.append("repeat_rule", args.repeat_rule!)
    formData.append("repeat_until", args.repeat_until!)
    formData.append("color", args.color!)
    formData.append("course_id", args.course_id!)
    formData.append("school_id", args.school_id!)
    try {
      const response = await axios.patch<Lesson>(url, formData)
      args.onFulfill({ id: response.data.id! })
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const isClassroomRunning = createAsyncThunk(
  "schools/isClassroomRunning",
  async (args: DetailLessonArgs, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/schools/${args.schoolId}/lessons/${args.lessonId}/is_classroom_running//`
    try {
      const response = await axios.get<{ status: string }>(url)
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const joinClassroomLink = createAsyncThunk(
  "schools/joinClassroomLink",
  async (args: DetailLessonArgs & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/schools/${args.schoolId}/lessons/${args.lessonId}/join_classroom_link/`
    try {
      const response = await axios.post<{ link: string }>(url)
      args.onFulfill({ id: response.data.link })
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const endClassroom = createAsyncThunk(
  "schools/endClassroom",
  async (args: DetailLessonArgs & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/schools/${args.schoolId}/lessons/${args.lessonId}/end_classroom/`
    try {
      const response = await axios.patch<Lesson>(url)
      args.onFulfill({ id: response.data.id! })
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

// Physical addresses

export const createPhysicalAddress = createAsyncThunk(
  "schools/createPhysicalAddress",
  async (args: PhysicalAddress & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/schools/physical-addresses/`
    try {
      const response = await axios.post<PhysicalAddress>(url, args)
      args.onFulfill({ id: response.data.id! })
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const updatePhysicalAddress = createAsyncThunk(
  "schools/updatePhysicalAddress",
  async (args: PhysicalAddress & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/schools/physical-addresses/${args.id}/`
    try {
      const response = await axios.patch<PhysicalAddress>(url, args)
      args.onFulfill({ id: response.data.id! })
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

// Requests

type ListRequestsArgs = {
  schoolId: string
  teacher: string
  search?: string
  course?: string
  course__in?: string
  status?: string
  page?: number
  page_size?: number
}

export const listRequests = createAsyncThunk(
  "schools/listRequests",
  async (args: ListRequestsArgs, { rejectWithValue }) => {
    const {
      schoolId,
      teacher,
      search,
      course,
      course__in,
      status,
      page,
      page_size,
    } = args
    const url = `${BASE_ENDPOINT}/schools/${schoolId}/requests/`
    try {
      const response = await axios.get<ApiPaginatedResponse<StudentRequest>>(
        url,
        {
          params: {
            teacher,
            search,
            course,
            course__in,
            status,
            page,
            page_size,
          },
        }
      )
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

type ListCourseRequestsArgs = {
  schoolId: string
  course: string
  student: string
}

export const listCourseRequests = createAsyncThunk(
  "schools/listCourseRequests",
  async (args: ListCourseRequestsArgs, { rejectWithValue }) => {
    const { schoolId, course, student } = args
    const url = `${BASE_ENDPOINT}/schools/${schoolId}/requests/`
    try {
      const response = await axios.get<ApiPaginatedResponse<StudentRequest>>(
        url,
        {
          params: { course, student },
        }
      )
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

type DetailRequestArgs = {
  schoolId: string
  requestId: string
}

export const getRequest = createAsyncThunk(
  "schools/getRequest",
  async ({ schoolId, requestId }: DetailRequestArgs, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/schools/${schoolId}/requests/${requestId}/`
    try {
      const response = await axios.get<StudentRequest>(url)
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const createRequest = createAsyncThunk(
  "schools/createRequest",
  async (args: StudentRequest & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/schools/${args.school_id}/requests/`
    try {
      const response = await axios.post<StudentRequest>(url, args)
      args.onFulfill({ id: response.data.id! })
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const updateRequest = createAsyncThunk(
  "schools/updateRequest",
  async (args: StudentRequest & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/schools/${args.school_id}/requests/${args.id}/`
    try {
      const response = await axios.patch<StudentRequest>(url, args)
      args.onFulfill({ id: response.data.id! })
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

type ProcessRequestArgs = {
  school_id: string
  request_id: string
  status: string
  student_id?: string
}

export const processRequest = createAsyncThunk(
  "schools/processRequest",
  async (args: ProcessRequestArgs & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/schools/${args.school_id}/requests/${args.request_id}/${args.status}/`
    try {
      const response = await axios.patch<StudentRequest>(url, args)
      args.onFulfill({ id: response.data.id! })
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const deleteRequest = createAsyncThunk(
  "schools/deleteRequest",
  async (args: DetailRequestArgs & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/schools/${args.schoolId}/requests/${args.requestId}/`
    try {
      const response = await axios.delete(url)
      args.onFulfill()
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

// Attachments

export const createAttachment = createAsyncThunk(
  "schools/createAttachment",
  async (args: Attachment & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/schools/attachments/`
    const formData = new FormData()
    formData.append("name", args.name)
    formData.append("file", args.file)
    try {
      const response = await axios.post<Attachment>(url, formData)
      args.onFulfill({ id: response.data.id! })
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

type DetailAttachmentArgs = {
  attachmentId: string
}

export const getAttachment = createAsyncThunk(
  "schools/getAttachment",
  async (args: DetailAttachmentArgs, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/schools/attachments/${args.attachmentId}/`
    try {
      const response = await axios.get<Attachment>(url)
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const deleteAttachment = createAsyncThunk(
  "schools/deleteAttachment",
  async (args: DetailAttachmentArgs & ApiAction, { rejectWithValue }) => {
    const url = `${BASE_ENDPOINT}/schools/attachments/${args.attachmentId}/`
    try {
      const response = await axios.delete(url)
      args.onFulfill()
      return response.data
    } catch (err: any) {
      args.onReject(err?.response?.data)
      return rejectWithValue(err?.response?.data)
    }
  }
)

// Users

type ListUsersArgs = {
  schoolId: string
  search?: string
  page?: number
  page_size?: number
}

export const listUsers = createAsyncThunk(
  "schools/listUsers",
  async (args: ListUsersArgs, { rejectWithValue }) => {
    const { schoolId, search, page, page_size } = args
    const url = `${BASE_ENDPOINT}/schools/${schoolId}/users/`
    try {
      const response = await axios.get<ApiPaginatedResponse<User>>(url, {
        params: { search, page, page_size },
      })
      return response.data
    } catch (err: any) {
      return rejectWithValue(err?.response?.data)
    }
  }
)

export const schoolsSlice = createSlice({
  name: "schools",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Schools

      .addCase(listSchools.pending, (state, action) => {
        state.apiStatus["listSchools"] = ApiStatus.PENDING
      })
      .addCase(listSchools.fulfilled, (state, action) => {
        state.schools = action.payload as ApiPaginatedResponse<School>
        state.apiStatus["listSchools"] = ApiStatus.FULFILLED
      })
      .addCase(listSchools.rejected, (state, action) => {
        state.apiError["listSchools"] = action.payload as ApiError
        state.apiStatus["listSchools"] = ApiStatus.REJECTED
      })

      .addCase(getSchool.pending, (state, action) => {
        state.apiStatus["getSchool"] = ApiStatus.PENDING
      })
      .addCase(getSchool.fulfilled, (state, action) => {
        state.school = action.payload as School
        state.apiStatus["getSchool"] = ApiStatus.FULFILLED
      })
      .addCase(getSchool.rejected, (state, action) => {
        state.apiError["getSchool"] = action.payload as ApiError
        state.apiStatus["getSchool"] = ApiStatus.REJECTED
      })

      // Levels

      .addCase(listLevels.pending, (state, action) => {
        state.apiStatus["listLevels"] = ApiStatus.PENDING
      })
      .addCase(listLevels.fulfilled, (state, action) => {
        state.levels = action.payload as ApiPaginatedResponse<Level>
        state.apiStatus["listLevels"] = ApiStatus.FULFILLED
      })
      .addCase(listLevels.rejected, (state, action) => {
        state.apiError["listLevels"] = action.payload as ApiError
        state.apiStatus["listLevels"] = ApiStatus.REJECTED
      })

      // Subjects

      .addCase(listSubjects.pending, (state, action) => {
        state.apiStatus["listSubjects"] = ApiStatus.PENDING
      })
      .addCase(listSubjects.fulfilled, (state, action) => {
        state.subjects = action.payload as ApiPaginatedResponse<Subject>
        state.apiStatus["listSubjects"] = ApiStatus.FULFILLED
      })
      .addCase(listSubjects.rejected, (state, action) => {
        state.apiError["listSubjects"] = action.payload as ApiError
        state.apiStatus["listSubjects"] = ApiStatus.REJECTED
      })

      // Students

      .addCase(listStudents.pending, (state, action) => {
        state.apiStatus["listStudents"] = ApiStatus.PENDING
      })
      .addCase(listStudents.fulfilled, (state, action) => {
        state.students = action.payload as ApiPaginatedResponse<Student>
        state.apiStatus["listStudents"] = ApiStatus.FULFILLED
      })
      .addCase(listStudents.rejected, (state, action) => {
        state.apiError["listStudents"] = action.payload as ApiError
        state.apiStatus["listStudents"] = ApiStatus.REJECTED
      })

      .addCase(updateStudent.pending, (state, action) => {
        state.apiStatus["updateStudent"] = ApiStatus.PENDING
      })
      .addCase(updateStudent.fulfilled, (state, action) => {
        state.apiStatus["updateStudent"] = ApiStatus.FULFILLED
      })
      .addCase(updateStudent.rejected, (state, action) => {
        state.apiError["updateStudent"] = action.payload as ApiError
        state.apiStatus["updateStudent"] = ApiStatus.REJECTED
      })

      .addCase(listStudentAssignments.pending, (state, action) => {
        state.apiStatus["listStudentAssignments"] = ApiStatus.PENDING
      })
      .addCase(listStudentAssignments.fulfilled, (state, action) => {
        state.assignments = action.payload as ApiPaginatedResponse<Assignment>
        state.apiStatus["listStudentAssignments"] = ApiStatus.FULFILLED
      })
      .addCase(listStudentAssignments.rejected, (state, action) => {
        state.apiError["listStudentAssignments"] = action.payload as ApiError
        state.apiStatus["listStudentAssignments"] = ApiStatus.REJECTED
      })

      .addCase(listStudentAttendances.pending, (state, action) => {
        state.apiStatus["listStudentAttendances"] = ApiStatus.PENDING
      })
      .addCase(listStudentAttendances.fulfilled, (state, action) => {
        state.attendances =
          action.payload as ApiPaginatedResponse<StudentAttendance>
        state.apiStatus["listStudentAttendances"] = ApiStatus.FULFILLED
      })
      .addCase(listStudentAttendances.rejected, (state, action) => {
        state.apiError["listStudentAttendances"] = action.payload as ApiError
        state.apiStatus["listStudentAttendances"] = ApiStatus.REJECTED
      })

      .addCase(getStudentStats.pending, (state, action) => {
        state.apiStatus["getStudentStats"] = ApiStatus.PENDING
      })
      .addCase(getStudentStats.fulfilled, (state, action) => {
        state.studentStats = action.payload as StudentStats
        state.apiStatus["getStudentStats"] = ApiStatus.FULFILLED
      })
      .addCase(getStudentStats.rejected, (state, action) => {
        state.apiError["getStudentStats"] = action.payload as ApiError
        state.apiStatus["getStudentStats"] = ApiStatus.REJECTED
      })

      // Teachers

      .addCase(listTeachers.pending, (state, action) => {
        state.apiStatus["listTeachers"] = ApiStatus.PENDING
      })
      .addCase(listTeachers.fulfilled, (state, action) => {
        state.teachers = action.payload as ApiPaginatedResponse<Teacher>
        state.apiStatus["listTeachers"] = ApiStatus.FULFILLED
      })
      .addCase(listTeachers.rejected, (state, action) => {
        state.apiError["listTeachers"] = action.payload as ApiError
        state.apiStatus["listTeachers"] = ApiStatus.REJECTED
      })

      .addCase(getTeacher.pending, (state, action) => {
        state.apiStatus["getTeacher"] = ApiStatus.PENDING
      })
      .addCase(getTeacher.fulfilled, (state, action) => {
        state.teacher = action.payload as Teacher
        state.apiStatus["getTeacher"] = ApiStatus.FULFILLED
      })
      .addCase(getTeacher.rejected, (state, action) => {
        state.apiError["getTeacher"] = action.payload as ApiError
        state.apiStatus["getTeacher"] = ApiStatus.REJECTED
      })

      .addCase(updateTeacher.pending, (state, action) => {
        state.apiStatus["updateTeacher"] = ApiStatus.PENDING
      })
      .addCase(updateTeacher.fulfilled, (state, action) => {
        state.apiStatus["updateTeacher"] = ApiStatus.FULFILLED
      })
      .addCase(updateTeacher.rejected, (state, action) => {
        state.apiError["updateTeacher"] = action.payload as ApiError
        state.apiStatus["updateTeacher"] = ApiStatus.REJECTED
      })

      .addCase(listTeacherAssignments.pending, (state, action) => {
        state.apiStatus["listTeacherAssignments"] = ApiStatus.PENDING
      })
      .addCase(listTeacherAssignments.fulfilled, (state, action) => {
        state.assignments = action.payload as ApiPaginatedResponse<Assignment>
        state.apiStatus["listTeacherAssignments"] = ApiStatus.FULFILLED
      })
      .addCase(listTeacherAssignments.rejected, (state, action) => {
        state.apiError["listTeacherAssignments"] = action.payload as ApiError
        state.apiStatus["listTeacherAssignments"] = ApiStatus.REJECTED
      })

      .addCase(createTeacherQualification.pending, (state, action) => {
        state.apiStatus["createTeacherQualification"] = ApiStatus.PENDING
      })
      .addCase(createTeacherQualification.fulfilled, (state, action) => {
        state.apiStatus["createTeacherQualification"] = ApiStatus.FULFILLED
      })
      .addCase(createTeacherQualification.rejected, (state, action) => {
        state.apiError["createTeacherQualification"] =
          action.payload as ApiError
        state.apiStatus["createTeacherQualification"] = ApiStatus.REJECTED
      })

      // Lessons

      .addCase(listLessons.pending, (state, action) => {
        state.apiStatus[`listLessons-${action.meta.arg.course || ""}`] =
          ApiStatus.PENDING
      })
      .addCase(listLessons.fulfilled, (state, action) => {
        state.lessons = action.payload as ApiPaginatedResponse<Lesson>
        state.apiStatus[`listLessons-${action.meta.arg.course || ""}`] =
          ApiStatus.FULFILLED
      })
      .addCase(listLessons.rejected, (state, action) => {
        state.apiError[`listLessons-${action.meta.arg.course || ""}`] =
          action.payload as ApiError
        state.apiStatus[`listLessons-${action.meta.arg.course || ""}`] =
          ApiStatus.REJECTED
      })

      .addCase(getLesson.pending, (state, action) => {
        state.apiStatus["getLesson"] = ApiStatus.PENDING
      })
      .addCase(getLesson.fulfilled, (state, action) => {
        state.lesson = action.payload as Lesson
        state.apiStatus["getLesson"] = ApiStatus.FULFILLED
      })
      .addCase(getLesson.rejected, (state, action) => {
        state.apiError["getLesson"] = action.payload as ApiError
        state.apiStatus["getLesson"] = ApiStatus.REJECTED
      })

      .addCase(createLesson.pending, (state, action) => {
        state.apiStatus["createLesson"] = ApiStatus.PENDING
      })
      .addCase(createLesson.fulfilled, (state, action) => {
        state.lesson = action.payload as Lesson
        state.apiStatus["createLesson"] = ApiStatus.FULFILLED
      })
      .addCase(createLesson.rejected, (state, action) => {
        state.apiError["createLesson"] = action.payload as ApiError
        state.apiStatus["createLesson"] = ApiStatus.REJECTED
      })

      .addCase(updateLesson.pending, (state, action) => {
        state.apiStatus["updateLesson"] = ApiStatus.PENDING
      })
      .addCase(updateLesson.fulfilled, (state, action) => {
        state.lesson = action.payload as Lesson
        state.apiStatus["updateLesson"] = ApiStatus.FULFILLED
      })
      .addCase(updateLesson.rejected, (state, action) => {
        state.apiError["updateLesson"] = action.payload as ApiError
        state.apiStatus["updateLesson"] = ApiStatus.REJECTED
      })

      // Classrooms

      .addCase(joinClassroomLink.pending, (state, action) => {
        state.apiStatus["joinClassroomLink"] = ApiStatus.PENDING
      })
      .addCase(joinClassroomLink.fulfilled, (state, action) => {
        state.apiStatus["joinClassroomLink"] = ApiStatus.FULFILLED
      })
      .addCase(joinClassroomLink.rejected, (state, action) => {
        state.apiError["joinClassroomLink"] = action.payload as ApiError
        state.apiStatus["joinClassroomLink"] = ApiStatus.REJECTED
      })

      // Physical Addresses

      .addCase(createPhysicalAddress.pending, (state, action) => {
        state.apiStatus["createPhysicalAddress"] = ApiStatus.PENDING
      })
      .addCase(createPhysicalAddress.fulfilled, (state, action) => {
        state.apiStatus["createPhysicalAddress"] = ApiStatus.FULFILLED
      })
      .addCase(createPhysicalAddress.rejected, (state, action) => {
        state.apiError["createPhysicalAddress"] = action.payload as ApiError
        state.apiStatus["createPhysicalAddress"] = ApiStatus.REJECTED
      })

      .addCase(updatePhysicalAddress.pending, (state, action) => {
        state.apiStatus["updatePhysicalAddress"] = ApiStatus.PENDING
      })
      .addCase(updatePhysicalAddress.fulfilled, (state, action) => {
        state.apiStatus["updatePhysicalAddress"] = ApiStatus.FULFILLED
      })
      .addCase(updatePhysicalAddress.rejected, (state, action) => {
        state.apiError["updatePhysicalAddress"] = action.payload as ApiError
        state.apiStatus["updatePhysicalAddress"] = ApiStatus.REJECTED
      })

      // Requests

      .addCase(listRequests.pending, (state, action) => {
        state.apiStatus["listRequests"] = ApiStatus.PENDING
      })
      .addCase(listRequests.fulfilled, (state, action) => {
        state.requests = action.payload as ApiPaginatedResponse<StudentRequest>
        state.apiStatus["listRequests"] = ApiStatus.FULFILLED
      })
      .addCase(listRequests.rejected, (state, action) => {
        state.apiError["listRequests"] = action.payload as ApiError
        state.apiStatus["listRequests"] = ApiStatus.REJECTED
      })

      .addCase(listCourseRequests.pending, (state, action) => {
        state.apiStatus[`listCourseRequests-${action.meta.arg.course || ""}`] =
          ApiStatus.PENDING
      })
      .addCase(listCourseRequests.fulfilled, (state, action) => {
        state.courseRequests[action.meta.arg.course!] =
          action.payload as ApiPaginatedResponse<StudentRequest>
        state.apiStatus[`listCourseRequests-${action.meta.arg.course || ""}`] =
          ApiStatus.FULFILLED
      })
      .addCase(listCourseRequests.rejected, (state, action) => {
        state.apiError[`listCourseRequests-${action.meta.arg.course || ""}`] =
          action.payload as ApiError
        state.apiStatus[`listCourseRequests-${action.meta.arg.course || ""}`] =
          ApiStatus.REJECTED
      })

      .addCase(getRequest.pending, (state, action) => {
        state.apiStatus["getRequest"] = ApiStatus.PENDING
      })
      .addCase(getRequest.fulfilled, (state, action) => {
        state.request = action.payload as StudentRequest
        state.apiStatus["getRequest"] = ApiStatus.FULFILLED
      })
      .addCase(getRequest.rejected, (state, action) => {
        state.apiError["getRequest"] = action.payload as ApiError
        state.apiStatus["getRequest"] = ApiStatus.REJECTED
      })

      .addCase(createRequest.pending, (state, action) => {
        state.apiStatus["createRequest"] = ApiStatus.PENDING
      })
      .addCase(createRequest.fulfilled, (state, action) => {
        state.request = action.payload as StudentRequest
        state.apiStatus["createRequest"] = ApiStatus.FULFILLED
      })
      .addCase(createRequest.rejected, (state, action) => {
        state.apiError["createRequest"] = action.payload as ApiError
        state.apiStatus["createRequest"] = ApiStatus.REJECTED
      })

      .addCase(updateRequest.pending, (state, action) => {
        state.apiStatus["updateRequest"] = ApiStatus.PENDING
      })
      .addCase(updateRequest.fulfilled, (state, action) => {
        state.request = action.payload as StudentRequest
        state.apiStatus["updateRequest"] = ApiStatus.FULFILLED
      })
      .addCase(updateRequest.rejected, (state, action) => {
        state.apiError["updateRequest"] = action.payload as ApiError
        state.apiStatus["updateRequest"] = ApiStatus.REJECTED
      })

      .addCase(processRequest.pending, (state, action) => {
        state.apiStatus["processRequest"] = ApiStatus.PENDING
      })
      .addCase(processRequest.fulfilled, (state, action) => {
        state.request = action.payload as StudentRequest
        state.apiStatus["processRequest"] = ApiStatus.FULFILLED
      })
      .addCase(processRequest.rejected, (state, action) => {
        state.apiError["processRequest"] = action.payload as ApiError
        state.apiStatus["processRequest"] = ApiStatus.REJECTED
      })

      .addCase(deleteRequest.pending, (state, action) => {
        state.apiStatus["deleteRequest"] = ApiStatus.PENDING
      })
      .addCase(deleteRequest.fulfilled, (state, action) => {
        state.apiStatus["deleteRequest"] = ApiStatus.FULFILLED
      })
      .addCase(deleteRequest.rejected, (state, action) => {
        state.apiError["deleteRequest"] = action.payload as ApiError
        state.apiStatus["deleteRequest"] = ApiStatus.REJECTED
      })

      // Attachments

      .addCase(createAttachment.pending, (state, action) => {
        state.apiStatus["createAttachment"] = ApiStatus.PENDING
      })
      .addCase(createAttachment.fulfilled, (state, action) => {
        state.attachments = [...state.attachments, action.payload as Attachment]
        state.apiStatus["createAttachment"] = ApiStatus.FULFILLED
      })
      .addCase(createAttachment.rejected, (state, action) => {
        state.apiError["createAttachment"] = action.payload as ApiError
        state.apiStatus["createAttachment"] = ApiStatus.REJECTED
      })

      .addCase(getAttachment.pending, (state, action) => {
        state.apiStatus["getAttachment"] = ApiStatus.PENDING
      })
      .addCase(getAttachment.fulfilled, (state, action) => {
        state.attachments = [...state.attachments, action.payload as Attachment]
        state.apiStatus["getAttachment"] = ApiStatus.FULFILLED
      })
      .addCase(getAttachment.rejected, (state, action) => {
        state.apiError["getAttachment"] = action.payload as ApiError
        state.apiStatus["getAttachment"] = ApiStatus.REJECTED
      })

      .addCase(deleteAttachment.pending, (state, action) => {
        state.apiStatus["deleteAttachment"] = ApiStatus.PENDING
      })
      .addCase(deleteAttachment.fulfilled, (state, action) => {
        state.apiStatus["deleteAttachment"] = ApiStatus.FULFILLED
      })
      .addCase(deleteAttachment.rejected, (state, action) => {
        state.apiError["deleteAttachment"] = action.payload as ApiError
        state.apiStatus["deleteAttachment"] = ApiStatus.REJECTED
      })

      // Users

      .addCase(listUsers.pending, (state, action) => {
        state.apiStatus["listUsers"] = ApiStatus.PENDING
      })
      .addCase(listUsers.fulfilled, (state, action) => {
        state.users = action.payload as ApiPaginatedResponse<User>
        state.apiStatus["listUsers"] = ApiStatus.FULFILLED
      })
      .addCase(listUsers.rejected, (state, action) => {
        state.apiError["listUsers"] = action.payload as ApiError
        state.apiStatus["listUsers"] = ApiStatus.REJECTED
      })
  },
})

export default schoolsSlice.reducer
