import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import Cookies from "universal-cookie"

import { BASE_ENDPOINT, COOKIE_KEY } from "../../constants"
import { RootState } from "../../store"
import { User } from "../../types"

const cookies = new Cookies()

export const accountsApi = createApi({
  reducerPath: "accountsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_ENDPOINT}/accounts/`,
    prepareHeaders: (headers, { getState }) => {
      const token =
        (getState() as RootState).accounts.auth.access ||
        cookies.get(COOKIE_KEY.access)
      if (token) {
        headers.set("authorization", `Bearer ${token}`)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    getUser: builder.query<User, string>({
      query: (userId) => `users/${userId}/`,
    }),
  }),
})

export const { useGetUserQuery } = accountsApi
